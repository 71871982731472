import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'



Vue.use(VueRouter)
//获取原型对象上的push函数
const originalReplace = VueRouter.prototype.replace;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import(/* webpackChunkName: "about" */ '../views/survey.vue')
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import(/* webpackChunkName: "about" */ '../views/enterprise.vue')
  },
  {
    path: '/us',
    name: 'us',
    component: () => import(/* webpackChunkName: "about" */ '../views/us.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/product.vue'),
  },
  {
    path: '/realTimeInfo',
    name: 'realTimeInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/realTimeInfo.vue')
  },
  {
    path:'/productDetail/:id', 
    name:'productDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/productDetail.vue'),
  },
  {
    path:'/realTimeInfoDetail/:id', 
    name:'realTimeInfoDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/realTimeInfoDetail.vue'),
  },
]

const router = new VueRouter({
  mode: "hash", //为了打包后,前端能看到
  base: process.env.BASE_URL,
  routes
})



export default router
