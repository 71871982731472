<!--
 * @Author: yjx
 * @Date: 2022-11-09 10:55:40
 * @LastEditTime: 2022-11-10 16:24:49
 * @LastEditors: your name
 * @Description: 
 * @FilePath: /pc-shaxianyi-master/pc-shaxianyi/src/App.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->

<template>
  <div id="app">
    <div class="box">
      <!-- 导航条 -->
      <nav class="nav">
        <div class="right">
          <img style="width: 145px;height: 79px;margin-top: 10px;" src="./assets/image/index/logo.png" alt="logo">
        </div>
        <div class="left">
          <router-link to="/" class="navfontColor">首页</router-link>
          <router-link to="/survey" class="navfontColor">公司概况</router-link>
          <router-link to="/product" class="navfontColor">产品中心</router-link>
          <router-link to="/enterprise" class="navfontColor">企业服务</router-link>
          <router-link to="/realTimeInfo" class="navfontColor">资讯中心</router-link>
          <router-link to="/us" class="navfontColor">联系我们</router-link>
        </div>
      </nav>
      <router-view />
    </div>
  </div>
</template>

<script>


export default {
  mounted() {
    var sUserAgent = navigator.userAgent.toLowerCase();
        var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        var bIsMidp = sUserAgent.match(/midp/i) == "midp";
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        var bIsAndroid = sUserAgent.match(/android/i) == "android";
        var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
        if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
          location.href = 'http://183.6.107.60:16000/#/'; //字符串内填写可访问的手机版域名，例如m.xxx.com，如果没有域名可直接填写/m/index.html
        }else{
          // location.href = 'http://183.6.107.60:17000/#/---pc';
        }
  },
}
</script>

<style lang="less" scoped>
.navfontColor {
  color: #fff;
}

#app {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 1920px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav {
    width: 1200px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right,
    .left {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      position: relative;
      z-index: 100;
    }

    .left {
      font-size: 20px;
      width: 560px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}
</style>
