<!--
 * @Author: yjx
 * @Date: 2022-11-09 10:55:40
 * @LastEditTime: 2022-11-09 15:24:20
 * @LastEditors: your name
 * @Description: 
 * @FilePath: /pc-shaxianyi-master/pc-shaxianyi/src/views/HomeView.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="box">
    <img style="margin-top: -100px;" src="../assets/image/index/indexBanner.png" alt="">
    <div class="content">
      <div class="main">
        <div class="one">中 国 棉 纺 产 业 运 营 商</div>
        <div class='two'>以 科 技 创 新 为 产 业 赋 能</div>
        <div class="three">
          <img style="width: 100px;height: 100px; margin-right: 20px;" src="../assets/image/index/icon1.svg" />
          <img style="width: 100px;height: 100px; margin-right: 20px;" src="../assets/image/index/icon2.svg" />
          <img style="width: 100px;height: 100px; margin-right: 20px;" src="../assets/image/index/icon3.svg" />
          <img style="width: 100px;height: 100px; margin-right: 20px;" src="../assets/image/index/icon4.svg" />
          <img style="width: 100px;height: 100px; margin-right: 20px;" src="../assets/image/index/icon5.svg" />
        </div>
      </div>
      <div class="bottom">
        <div class="bottomLeft">
        <div class="bottomLeftOne">全国统一热线服务</div>
        <div class="bottomLeftTwo">020-32642809</div>
      </div>
      <div class="bottomRight">
        <div class="bottomRightOne">
          <img  v-if="imgShow" style="width:238px ;height: 142px;" src="../assets/image/index/app.png" alt="">
          <div class="bottomRightB"  @mousemove="appMove" @mouseout="appOut" @mouseleave="appLeave">
            <img style="width: 16px;height: 23px;margin-right: 14px;" src="../assets/image/index/shoujilogo.png" alt="">
            <div style="16px">纱纤亿APP</div>
          </div>
        </div>
        <div class="bottomRightTwo">
          <div class="one">Copyright 2021 广东纱纤亿信息科技股份有限公司</div>
          <div class="two">版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #fff;">粤ICP备2021164990号</a></div>
        </div>
      </div>
      </div>
    </div>

    <div @click="btn" style="width: 66px;height: 66px; position: fixed; bottom: 300px; right: 20px;">
      <img style="width: 100%; height: 100%;" src="../assets/kefu.png" alt="">
    </div>
  </div>
</template>
<script>

export default {
  name: '',
  components: {

  },
  mixins: [],
  props: {

  },
  data() {
    return {
      imgShow:false,
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {
    btn(){
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc4d6d065bb261e369?enc_scene=ENCwsJQx1nSHJk4fLpFebP2Fqfg3RLN9XTcP1hd3GCtVYiprEHUgT988bJcfLzcggUPT';
    },
    appMove(){
      this.imgShow = true
      console.log('appMove');
    },
    appOut(){
      this.imgShow = false
      console.log('appOut');
    },
    appLeave(){
      this.imgShow = false
      console.log('appLeave');
    }
  }
};
</script>
<style lang='less' scoped>
.box {
  width: 1920px;
  height: 960px;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;

  .content {
    width: 1200px;
    height: 860px;
    position: absolute;
    margin: -70px 0 0 ;
    display: flex;
    justify-content: center;
    // background-color: #11283D;

    .main {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -100px;
      .one {
        font-size: 34px;
      }

      .two {
        font-size: 64px;
        margin: 34px 0 36px;
      }
    }

    .bottom{
      display: flex;
      width: 1200px;
      height: 860px;
      align-items: flex-end;
      justify-content: space-between;
      .bottomLeft {
      display: flex;
      flex-direction: column;
      text-align: left;

      .bottomLeftOne {
        font-size: 14px;
      }

      .bottomLeftTwo {
        margin-top: 12px;
        font-size: 24px;
      }
    }

    .bottomRight {
      display: flex;

      .bottomRightOne {
        display: flex;
        flex-direction: column;
        align-items: center;

        .bottomRightB {
          width: 158px;
          height: 42px;
          margin-top: 21px;
          border: 1px solid #FFFFFF;
          border-radius: 21px;
          margin-right: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .bottomRightTwo {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
        font-size: 14px;
        line-height: 24px;
      }
    }
    }
  }

}
</style>
